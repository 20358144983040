<template>
    <div class="preview">
        <p class="preview-tip">温馨提醒：图片仅供参考，商品以实物为准。（支持左右键查看图片哦）</p>
        <el-carousel trigger="click" height="90vh">
            <el-carousel-item v-for="item in imgs" :key="item" :loop="false"  :autoplay="false">
                <img class="img" :src="item" />
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import {  toRefs, reactive } from "vue";
import { useStore } from "vuex";



export default {
    name: "preview",
    setup() {
        const store = useStore() 
        let state = reactive ({
            ali_oss: store.state.ali_oss,
            userid: store.state.userId,
            imgs: store.state.detail_img
        })

        return {
            ...toRefs(state),
        }
    },
};
</script>

<style lang="less" scoped>
.preview {
    background: #fff;
    padding: 1px 20vw;
    height: 100vh;
}
.preview-tip {
    font-size: 16px;
    color: #333;
    margin: 10px 0;
    text-align: center;
}
.img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

</style>
